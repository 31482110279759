html {
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
}
@media screen and (((min-aspect-ratio: 1/1) and (min-width: 1200px) and (max-width: 1439px)) or ((max-aspect-ratio: 1/1) and (min-height: 1200px) and (max-height: 1439px))) {
  html {
    font-size: 18px;
  }
}

@media screen and (((min-aspect-ratio: 1/1) and (min-width: 1440px)) or ((max-aspect-ratio: 1/1) and (min-height: 1440px))) {
  html {
    font-size: 20px;
  }
}

body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

svg {
  display: block;
  vertical-align: middle;
  shape-rendering: auto;
  text-rendering: optimizeLegibility;
}